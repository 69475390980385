footer {
    display: grid;
    grid-template-areas:    "company callToAction mainLinks"
                            "otherLinks otherLinks otherLinks"
                            "mentions mentions mentions";
    grid-template-columns: 2fr 1fr 8em;
    grid-template-rows: auto auto auto;
    grid-column-gap: 4em;
    grid-row-gap: 3em;
    padding: 2.5em 12%;
    color: #FFFFFF;
    background-color: #000000;
}

.Company {
    grid-area: company;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Company > * {
    display: inline-block;
}

.Company > *:first-child {
    align-self: flex-start;
}

.LogoContainer {
    height: 14em;
    margin-right: 2.5em;
}

.CompanyDetails > * {
    display: block;
}

.CompanyDetails > *:first-child {
    max-width: 20em;
    margin-bottom: 2.5em;
    font-family: AmericanCaptainPatrius1;
    letter-spacing: .025em;
    word-spacing: .1em;
}

.CompanyDetails > *:nth-child(2) {
    margin-bottom: .75em;
    font-family: AmericanCaptain;
    font-size: 1.25em;
    letter-spacing: .04em;
    word-spacing: .12em;
}

.ContactInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.5em;
}

.ContactInfo img {
    height: 1.5em;
    margin-right: .75em;
}

.CompanyDetails .ContactInfo:not(:last-of-type) {
    margin-bottom: 2em;
}

.CallToAction {
    grid-area: callToAction;
}

.CallToAction > span {
    display: block;
    margin-bottom: 1.5em;
    font-family: AmericanCaptainPatrius1;
    letter-spacing: .025em;
    word-spacing: .1em;
}

.CallToAction > *:nth-child(2) {
    margin: auto;
}

.SocialMediaLinks {
    display: flex;
    flex-direction: row;
    margin: 4em 0 0 0;
    list-style: none;
}

.SocialMediaLinks li:not(:first-of-type) {
    margin-left: 3em;
}

.SocialMediaLinks svg {
    display: block;
    height: 2.5em;
    width: 2.5em;
    color: #FFFFFF;
    -webkit-transform: perspective(1px) scale(1);
    -moz-transform: perspective(1px) scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: perspective(1px) scale(1);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: transform 150ms ease, color 150ms ease;
    -moz-transition: transform 150ms ease, color 150ms ease;
    -o-transition: transform 150ms ease, color 150ms ease;
    -ms-transition: transform 150ms ease, color 150ms ease;
    transition: transform 150ms ease, color 150ms ease;
}

.SocialMediaLinks a:hover svg,
.SocialMediaLinks a:focus svg,
.SocialMediaLinks a:active svg {
    color: #FF7070;
    -webkit-transform: perspective(1px) scale(1.05);
    -moz-transform: perspective(1px) scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: perspective(1px) scale(1.05);
}

.MainLinks {
    grid-area: mainLinks;
    margin-top: 2em;
}

.MainLinks li:not(:last-child) {
    margin-bottom: 3em;
}

.ContactInfo a,
.MainLinks a,
.OtherLnks a {
    color: #FFFFFF;
    text-decoration: none;
}

.ContactInfo a:focus,
.ContactInfo a:hover,
.MainLinks a:focus,
.MainLinks a:hover,
.OtherLnks a:focus,
.OtherLnks a:hover {
    text-decoration: underline;
}

.ContactInfo a:active,
.MainLinks a:active,
.MainLinks a.active,
.OtherLnks a:active,
.OtherLnks a.active {
    color: #FF7070;
    text-decoration: underline;
}

.OtherLnks {
    grid-area: otherLinks;
}

.OtherLnks ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MainRouteLinkItemDivider {
    display: none;
}

.LinkItemDivider {
    margin: 0 1.5em;
    color: #AAAAAA;
}

.Mentions {
    grid-area: mentions;
}

.Mentions * {
    font-family: AmericanCaptain;
}

.OwnerMention {
    display: block;
}

.WebDesignerMention {
    height: 1.5em;
    display: block;
    margin-top: .5em;
}

.WebDesignerMention img {
    height: 100%;
    margin: 0 .5em 0 .5em;
}

.WebDesignerMention a {
    color: #FFFFFF;
}

@media screen and (max-width: 200em) {
    footer {
        padding: 2em 8%;
    }
}

@media screen and (max-width: 160em) {
    footer {
        padding: 1.5em 6%;
    }
}

@media screen and (max-width: 120em) {
    footer {
        padding: 1.5em 4%;
    }
}

@media screen and (max-width: 85.375em) {
    footer {
        padding: 1.5em 2%;
    }
}

@media screen and (max-width: 64em) {
    footer {
        grid-template-areas:    "company callToAction"
                                "mainLinks mainLinks"
                                "otherLinks otherLinks"
                                "mentions mentions";
        grid-template-columns: 2.5fr 1.5fr;
        grid-template-rows: auto auto auto auto;
        padding: 1.5em 1.5em;
    }

    .LogoContainer {
        height: 12em;
    }

    .MainLinks {
        margin-top: 1em;
    }

    .MainLinks ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .MainLinks li:not(:last-child) {
        margin-bottom: 0;
    }

    .MainRouteLinkItemDivider {
        display: inline-block;
        margin: 0 2.25em;
        color: #AAAAAA;
    }
}

@media screen and (max-width: 36em) {
    footer {
        width: auto;
        grid-template-areas:    "company"
                                "callToAction"
                                "mainLinks"
                                "otherLinks"
                                "mentions";
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        grid-row-gap: 2em;
    }

    .Company {
        justify-content: space-around;
    }

    .CompanyDetails > *:first-child {
        max-width: 22em;
    }

    .LogoContainer {
        margin-right: 0;
    }

    .CallToAction {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin: 2em 0 1em 0;
    }

    .CallToAction > span {
        max-width: 14em;
        margin-bottom: 0;
        margin-right: .75em;
    }

    .CallToAction > *:nth-child(2),
    .SocialMediaLinks {
        margin: auto auto auto 0;
    }

    .SocialMediaLinks > li:not(:last-of-type) {
        margin-right: 1em;
    }
}

@media screen and (max-width: 30em) {
    footer {
        padding: 1em 1em;
    }

    .LogoContainer {
        height: 10em;
        margin-right: 2em;
    }

    .CallToAction {
        display: grid;
        grid-template-areas:    "contactUsSegway socialLinks"
                                "contactUsButton .";
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        grid-column-gap: 3em;
        grid-row-gap: 1em;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .CallToAction > span {
        grid-area: contactUsSegway;
        margin-right: 0;
    }

    .CallToAction > *:nth-child(2) {
        grid-area: contactUsButton;
    }

    .CallToAction > *:last-child {
        grid-area: socialLinks;
        margin-left: 0;
    }
}

@media screen and (max-width: 23.5em) {
    .LogoContainer {
        height: 6em;
        margin-right: 1em;
    }

    .CompanyDetails > *:first-child {
        max-width: 20em;
        margin-bottom: 2em;
    }
}

@media screen and (max-width: 20em) {
    .LogoContainer {
        display: none;
    }

    .CompanyDetails > *:first-child {
        max-width: 20em;
        margin-bottom: 2em;
    }

    .SocialMediaLinks > li:not(:last-of-type) {
        margin-right: 0;
    }

    .MainRouteLinkItemDivider {
        margin: 0 1.25em;
    }

    .LinkItemDivider {
        margin: 0 .75em;
    }
}
