.Input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Input label {
    margin: 0 0 .25em 0;
    font-family: AmericanCaptainPatrius;
    line-height: 1;
}

.RequiredFieldIndicator {
    margin-left: .25em;
    color: var(--red-color-hex);
}

.Input input,
.Input textarea {
    padding: .3em .5em;
    font-size: 1em;
    border-radius: .25em;
    border: .15em solid #666666;
    background-color: #D0D0D0;
}

.Input input:hover,
.Input input:focus,
.Input textarea:hover,
.Input textarea:focus {
    border-color: #000000;
    outline-color: #000000;
    background-color: #FFFFFF;
}

.Input input:focus,
.Input textarea:focus {
    box-shadow: 0 0 .05em .1em inset red;
}

.Input input:-webkit-autofill,
.Input input:-webkit-autofill:hover,
.Input input:-webkit-autofill:focus,
.Input textarea:-webkit-autofill,
.Input textarea:-webkit-autofill:hover,
.Input textarea:-webkit-autofill:focus {
    -webkit-text-fill-color: #000000;
    -webkit-box-shadow: 0 0 0 1em #d9ffb3 inset !important;
}

.Error input,
.Error textarea {
    background-color: hsl(0, 100%, 90%);
}

.ErrorMessageContainer {
    height: 1.25em;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: .25em 0 0 .5em;
}

.ErrorMessageContainer > img {
    height: 100%;
    margin-right: .25em;
}

.ErrorMessageContainer > span {
    display: block;
    line-height: 1;
    color: var(--red-color-hex);
}
