.NavigationItems {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.NavigationItems > *:not(:last-child) {
    margin-right: 2em;
}

.NavigationItems hr {
    display: none;
}

@media screen and (max-width: 48em) {
    .NavigationItems {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: .2em solid #AAAAAA;
        border-bottom: .2em solid #AAAAAA;
    }

    .NavigationItems > *:not(:last-child) {
        margin-right: 0;
        border-bottom: .1em solid #888888;
    }

    .NavigationItems hr {
        height: .15em;
        width: 100%;
        display: block;
        margin: 0;
        font-size: 1em;
        color: #888888;
        background-color: #888888;
        border: none;
    }
}
