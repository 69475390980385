.IntroContent {
    max-width: 45em;
    display: block;
    font-size: 1.2em;
    line-height: 1.5;
}

.OtherResources {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.ContentNavPanel {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    position: -webkit-sticky;
    position: sticky;
    padding: 2em 1em 1em 1em;
    border-right: .15em solid #000000;
    background-color: #FFFFFF;
}

.WithoutNotification {
    height: calc(100vh - 4em);
    top: 4em;
}

.WithNotification {
    height: calc(100vh - 8.05em);
    top: 8.05em;
}

.CategoryContainer > b {
    font-size: 1.25em;
}

.CategoryContainer a {
    display: inline-block;
    font-size: 1.1em;
    text-decoration: none;
    color: #0000EE;
}

.CategoryContainer a:focus,
.CategoryContainer a:hover {
    text-decoration: underline;
}

.CategoryContainer a.active {
    color: #551A8B;
    text-decoration: underline;
}

.CategoryContainer ul {
    margin: 2em 0 1em 1.25em;
}

.CategoryContainer ul > li {
    line-height: 1;
}

.CategoryContainer ul > li:not(:last-child) {
    margin-bottom: 2.25em;
}

.NoResourcesMessage {
    margin: 1em;
    font-family: AmericanCaptainPatrius;
    font-size: 1.5em;
    line-height: 1.25;
    color: var(--red-color-hex);
}

.NoResourcesMessageSearchParam {
    font-family: AmericanCaptainPatrius1;
    color: #000000;
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 1em 2em 1em;
}

.Content > ul {
    width: 100%;
    padding: 1em 0 2em 0;
}

.Content > ul > li:not(:first-child) {
    padding-top: 1.75em;
}

.Content > ul > li:not(:last-child) {
    padding-bottom: 1.25em;
    border-bottom: .1em solid #999999;
}

@media screen and (max-width: 64em) {
    .IntroContent {
        max-width: 40em;
    }
}

@media screen and (max-width: 36em) {
    .OtherResources {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* overflow: visible; */
    }

    .ContentNavPanel {
        height: auto;
        width: 100%;
        flex: 1;
        padding: 1.5em 1em 1em 1em;
        border-right: none;
        border-bottom: .15em solid #000000;
        -webkit-box-shadow: 0 .1em .2em rgb(0, 0, 0, 0.9);
        -moz-box-shadow: 0 .1em .2em rgb(0, 0, 0, 0.9);
        box-shadow: 0 .1em .2em rgb(0, 0, 0, 0.9);
        z-index: 200;
    }

    .CategoryContainer ul {
        display: flex;
        flex-direction: row;
        margin: 1.5em 0 1em 2em;
    }

    .CategoryContainer ul > li:not(:last-child) {
        margin-bottom: 0;
        margin-right: 2.5em;
    }
}
