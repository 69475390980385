.Main {
    /* padding: 1em 1em; */
    background-color: purple;
    background-color: #FFFFFF;
}

@media screen and (max-width: 48em) {
    .Main {
        /* padding: .5em .5em; */
    }
}
