.Spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Animation {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Animation > span {
    height: 1em;
    width: 1em;
    display: block;
    border-radius: 50%;
    border: .05em solid #FFFFFF;
    background-color: var(--red-color-hex);
    animation: spinner 600ms linear infinite;
}

.Animation > span:not(:last-of-type) {
    margin-right: 1.5em;
}

.Animation > span:first-of-type {
    animation-delay: 0ms;
}

.Animation > span:nth-of-type(2) {
    animation-delay: 150ms;
}

.Animation > span:last-of-type {
    animation-delay: 300ms;
}

.Message {
    display: block;
    margin-top: 1em;
    color: #FFFFFF;
    font-size: 1.5em;
    letter-spacing: .05em;
    word-spacing: .1em;
}

@-moz-keyframes spinner {
    0%  { transform: scale(1,1)   translateY(0); }
    10%  { transform: scale(1.1, .9) translateY(0); }
    30%  { transform: scale(.9, 1.1) translateY(-1.5em); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-.4em); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}

@-o-keyframes spinner {
    0%  { transform: scale(1,1)   translateY(0); }
    10%  { transform: scale(1.1, .9) translateY(0); }
    30%  { transform: scale(.9, 1.1) translateY(-1.5em); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-.4em); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}

@-ms-keyframes spinner {
    0%  { transform: scale(1,1)   translateY(0); }
    10%  { transform: scale(1.1, .9) translateY(0); }
    30%  { transform: scale(.9, 1.1) translateY(-1.5em); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-.4em); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}

@-webkit-keyframes spinner {
    0%  { transform: scale(1,1)   translateY(0); }
    10%  { transform: scale(1.1, .9) translateY(0); }
    30%  { transform: scale(.9, 1.1) translateY(-1.5em); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-.4em); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}

@keyframes spinner {
    0%  { transform: scale(1,1)   translateY(0); }
    10%  { transform: scale(1.1, .9) translateY(0); }
    30%  { transform: scale(.9, 1.1) translateY(-1.5em); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-.4em); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}
