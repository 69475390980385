.StyledLink {
    display: inline-block;
    font-size: 1em;
    /* letter-spacing: .1em;
    word-spacing: .2em; */
    text-decoration: none;
    border-radius: .5em;
    color: #000000;
    background-color: #CFC230;
    -webkit-transform: perspective(1px) scale(1);
    -moz-transform: perspective(1px) scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: perspective(1px) scale(1);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
}

.Small {
    padding: .4em .6em;
}

.Medium {
    padding: .6em .8em;
}

.Large {
    padding: .8em 1em;
}

.Small,
.Medium {
    border: .15em solid #000000;
    -webkit-box-shadow: 0 .125em .125em rgba(0, 0, 0, .35), -.0625em .0625em .125em rgba(0, 0, 0, .35), .0625em .0625em .125em rgba(0, 0, 0, .35);
    -moz-box-shadow: 0 .125em .125em rgba(0, 0, 0, .35), -.0625em .0625em .125em rgba(0, 0, 0, .35), .0625em .0625em .125em rgba(0, 0, 0, .35);
    box-shadow: 0 .125em .125em rgba(0, 0, 0, .35), -.0625em .0625em .125em rgba(0, 0, 0, .35), .0625em .0625em .125em rgba(0, 0, 0, .35);
}

.Large {
    border: .2em solid #000000;
    -webkit-box-shadow: 0 .18em .18em rgba(0, 0, 0, .35), -.09em .09em .18em rgba(0, 0, 0, .35), .09em .09em .18em rgba(0, 0, 0, .35);
    -moz-box-shadow: 0 .18em .18em rgba(0, 0, 0, .35), -.09em .09em .18em rgba(0, 0, 0, .35), .09em .09em .18em rgba(0, 0, 0, .35);
    box-shadow: 0 .18em .18em rgba(0, 0, 0, .35), -.09em .09em .18em rgba(0, 0, 0, .35), .09em .09em .18em rgba(0, 0, 0, .35);
}



.Small:hover,
.Small:focus,
.Small:active,
.Medium:hover,
.Medium:focus,
.Medium:active {
    -webkit-box-shadow: 0 .15em .15em rgba(0, 0, 0, .4), -.07em .07em .15em rgba(0, 0, 0, .4), .07em .07em .15em rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 .15em .15em rgba(0, 0, 0, .4), -.07em .07em .15em rgba(0, 0, 0, .4), .07em .07em .15em rgba(0, 0, 0, .4);
    box-shadow: 0 .15em .15em rgba(0, 0, 0, .4), -.07em .07em .15em rgba(0, 0, 0, .4), .07em .07em .15em rgba(0, 0, 0, .4);
}

.Large:hover,
.Large:focus,
.Large:active {
    -webkit-box-shadow: 0 .22em .22em rgba(0, 0, 0, .4), -.1em .1em .22em rgba(0, 0, 0, .4), .1em .1em .22em rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 .22em .22em rgba(0, 0, 0, .4), -.1em .1em .22em rgba(0, 0, 0, .4), .1em .1em .22em rgba(0, 0, 0, .4);
    box-shadow: 0 .22em .22em rgba(0, 0, 0, .4), -.1em .1em .22em rgba(0, 0, 0, .4), .1em .1em .22em rgba(0, 0, 0, .4);
}

.StyledLink:hover,
.StyledLink:focus {
    background-color: #FFFF00;
    -moz-transform: perspective(1px) scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -webkit-transform: perspective(1px) scale(1.05);
    transform: perspective(1px) scale(1.05);
}

.StyledLink:active {
    -moz-transform: perspective(1px) scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: perspective(1px) scale(1.1);
    transform: perspective(1px) scale(1.1);
}
