.DrawerToggle {
    display: none;
}

@media screen and (max-width: 48em) {
    .DrawerToggle {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        outline-style: none;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    .DivWrapper {
        height: 2.5em;
        width: 2.5em;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .DivWrapper span {
        height: 3px;
        width: 100%;
        display: block;
        position: absolute;
        top: calc(50% - 1.5px);
        border-radius: 3px;
        background-color: #FFFFFF;
    }

    .ToggleOn .DivWrapper {
        transform: rotate(45deg);
        -moz-transition: transform 150ms linear 300ms;
        -o-transition: transform 150ms linear 300ms;
        -ms-transition: transform 150ms linear 300ms;
        -webkit-transition: transform 150ms linear 300ms;
        transition: transform 150ms linear 300ms;
    }

    .ToggleOff .DivWrapper {
        transform: rotate(0deg);
        -moz-transition: transform 100ms linear;
        -o-transition: transform 100ms linear;
        -ms-transition: transform 100ms linear;
        -webkit-transition: transform 100ms linear;
        transition: transform 100ms linear;
    }

    .DivWrapper::before,
    .DivWrapper::after {
        content: '';
        height: 3px;
        width: 100%;
        display: block;
        position: absolute;
        border-radius: 3px;
        background-color: #FFFFFF;
    }

    .DrawerToggle:hover .DivWrapper span,
    .DrawerToggle:hover .DivWrapper::before,
    .DrawerToggle:hover .DivWrapper::after,
    .DrawerToggle:focus .DivWrapper span,
    .DrawerToggle:focus .DivWrapper::before,
    .DrawerToggle:focus .DivWrapper::after {
        background-color: #FF7070;
    }

    .ToggleOn .DivWrapper::before {
        top: calc(50% - 1.5px);
        -moz-transition: top 75ms linear;
        -o-transition: top 75ms linear;
        -ms-transition: top 75ms linear;
        -webkit-transition: top 75ms linear;
        transition: top 75ms linear;
    }

    .ToggleOn .DivWrapper::after {
        top: calc(50% - 1.5px);
        -moz-transition: top 75ms linear;
        -o-transition: top 75ms linear;
        -ms-transition: top 75ms linear;
        -webkit-transition: top 75ms linear;
        transition: top 75ms linear;
    }

    .ToggleOn .DivWrapper span {
        transform: rotate(90deg);
        -moz-transition: transform 75ms linear 125ms;
        -o-transition: transform 75ms linear 125ms;
        -ms-transition: transform 75ms linear 125ms;
        -webkit-transition: transform 75ms linear 125ms;
        transition: transform 75ms linear 125ms;
    }

    .ToggleOff .DivWrapper::before {
        top: 0;
        -moz-transition: top 100ms linear 400ms;
        -o-transition: top 100ms linear 400ms;
        -ms-transition: top 100ms linear 400ms;
        -webkit-transition: top 100ms linear 400ms;
        transition: top 100ms linear 400ms;
    }

    .ToggleOff .DivWrapper::after {
        top: calc(100% - 3px);
        -moz-transition: top 100ms linear 400ms;
        -o-transition: top 100ms linear 400ms;
        -ms-transition: top 100ms linear 400ms;
        -webkit-transition: top 100ms linear 400ms;
        transition: top 100ms linear 400ms;
    }

    .ToggleOff .DivWrapper span {
        transform: rotate(0);
        -moz-transition: transform 100ms linear 200ms;
        -o-transition: transform 100ms linear 200ms;
        -ms-transition: transform 100ms linear 200ms;
        -webkit-transition: transform 100ms linear 200ms;
        transition: transform 100ms linear 200ms;
    }
}
