.PageIntro {
    display: grid;
    grid-template-areas:    "heading logoContainer"
                            "intro logoContainer"
                            "tagLine logoContainer";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto;
    grid-column-gap: 5em;
    padding: 2em 5%;
    border-bottom: .15em solid var(--red-color-hex);
    background-color: hsl(38, 35%, 77%);
}

.PageIntro h1 {
    grid-area: heading;
}

.IntroContent {
    height: 100%;
    max-width: 45em;
    grid-area: intro;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1em;
}

.Tagline {
    grid-area: tagLine;
    font-size: 1.5em;
    font-weight: bold;
    font-style: italic;
    letter-spacing: .05em;
    line-height: 1.5;
    margin-top: 1.3em;
}

.Tagline > * {
    display: inline-block;
}

.Tagline > span:first-of-type {
    margin-right: .5em;
}

.Tagline > span:nth-of-type(2) {
    margin-right: .5em;
    color: #0033BB;
}

.Tagline > span:nth-of-type(3) {
    color: var(--red-color-hex);
}

.LogoImage {
    grid-area: logoContainer;
    height: 18em;
}

@media screen and (max-width: 100em) {
    .PageIntro {
        padding: 1em;
    }
}

@media screen and (max-width: 85.375em) {
    .PageIntro {
        grid-column-gap: 3em;
        padding: 1em 5%;
    }
}

@media screen and (max-width: 64em) {
    .PageIntro {
        padding: 1em;
    }

    .IntroContent {
        max-width: 40em;
    }

    .LogoImage {
        height: 14em;
    }
}

@media screen and (max-width: 48em) {
    .PageIntro {
        grid-template-areas:    "heading heading"
                                "intro logoContainer"
                                "tagLine tagLine";
        grid-column-gap: 3em;
    }
}

@media screen and (max-width: 36em) {
    .LogoImage {
        height: 12em;
    }
}

@media screen and (max-width: 30em) {
    .PageIntro {
        grid-column-gap: 1.5em;
    }

    .LogoImage {
        height: 8em;
    }
}

@media screen and (max-width: 20em) {
    .PageIntro {
        display: block;
    }

    .LogoImage {
        display: none;
    }

    .Tagline {
        font-size: 1.25em;
    }
}
