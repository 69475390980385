header {
    width: 100vw;
    height: 4em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: .5em .75em;
    background-color: #000000;
    -webkit-box-shadow: 0 .075em .1em rgb(0, 0, 0, 0.9);
    -moz-box-shadow: 0 .075em .1em rgb(0, 0, 0, 0.9);
    box-shadow: 0 .075em .1em rgb(0, 0, 0, 0.9);
    z-index: 300;
}

.Title {
    font-family: AmericanCaptain;
    font-size: 1.75em;
    /* letter-spacing: .025em; */
    word-spacing: .1em;
    text-align: center;
    color: var(--red-color-hex);
    color: #FFFFFF;
    line-height: 1;
}

.DesktopOnly {
    display: block;
}

@media screen and (max-width: 48em) {
    header {
        padding: .5em .5em;
    }

    .Title {
        /* max-width: 10em; */
        margin: 0 .5em;
        font-size: 1.5em;
    }

    .DesktopOnly {
        display: none;
    }
}
