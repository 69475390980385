:root {
    --base-desktop-font-size: 1em;
    --base-line-height: 1.25;
    --base-header-line-height: 1.25;
    --red-color-hex: #913916;
    --gold-color-hex: #88775C;
}

/*
***** z-index *****
400 - viewport modal
300 - Header
300 - notification
250 - side drawer backdrop
200 - other resources nav panel
130 - podcast card
100 - podcast card
50 - main and footer
10 - side drawer */

@font-face {
    font-family: 'AmericanCaptainPatrius';
    src: local('AmericanCaptainPatrius'),
        url(fonts/AmericanCaptainFamily/American Captain Patrius.otf) format('opentype'),
        url(fonts/AmericanCaptainFamily/American Captain Patrius.ttf) format('truetype');
}

@font-face {
    font-family: 'AmericanCaptainPatrius1';
    src: local('AmericanCaptainPatrius1'),
        url(fonts/AmericanCaptainFamily/American Captain Patrius 01.otf) format('opentype'),
        url(fonts/AmericanCaptainFamily/American Captain Patrius 01.ttf) format('truetype');
}

@font-face {
    font-family: 'AmericanCaptainPatrius2';
    src: local('AmericanCaptainPatrius2'),
        url(fonts/AmericanCaptainFamily/American Captain Patrius 02.otf) format('opentype'),
        url(fonts/AmericanCaptainFamily/American Captain Patrius 02.ttf) format('truetype');
}

@font-face {
    font-family: 'AmericanCaptainEternal';
    src: local('AmericanCaptainEternal'),
        url(fonts/AmericanCaptainFamily/American Captain Eternal.otf) format('opentype'),
        url(fonts/AmericanCaptainFamily/American Captain Eternal.ttf) format('truetype');
}

@font-face {
    font-family: 'AmericanCaptain';
    src: local('AmericanCaptain'),
        url(fonts/AmericanCaptainFamily/American Captain.otf) format('opentype'),
        url(fonts/AmericanCaptainFamily/American Captain.ttf) format('truetype');
}

@font-face {
    font-family: 'AmericanCaptain1';
    src: local('AmericanCaptain1'),
        url(fonts/AmericanCaptainFamily/American Captain 01.otf) format('opentype'),
        url(fonts/AmericanCaptainFamily/American Captain 01.ttf) format('truetype');
}

* {
    box-sizing: border-box;
    font-family: AmericanCaptainPatrius1;
}

body {
  margin: 0;
  font-size: var(--base-desktop-font-size);
  line-height: var(--base-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    font-family: AmericanCaptainPatrius;
}

a, button {
    font-family: AmericanCaptain1;
    letter-spacing: .1em;
    word-spacing: .1em;
}

a {
    text-underline-offset: .1em;
}

ul, li {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

body {
    font-size: 1.3vw;

    /* adjust padding at each level too * * * * * * * * * * * * * * * * * * * *

      */
}

@media screen and (max-width: 200em) { /* 3200px */
    body {
        font-size: calc(var(--base-desktop-font-size) * 2.2);
    }
}

@media screen and (max-width: 180em) { /* 2880px */
    body {
        font-size: calc(var(--base-desktop-font-size) * 2);
    }
}

@media screen and (max-width: 160em) { /* 2560px */
    body {
        font-size: calc(var(--base-desktop-font-size) * 1.8);
    }
}

@media screen and (max-width: 140em) { /* 2240px */
    body {
        font-size: calc(var(--base-desktop-font-size) * 1.6);
    }
}

@media screen and (max-width: 120em) { /* 1920px */
    body {
        font-size: calc(var(--base-desktop-font-size) * 1.4);
    }
}

@media screen and (max-width: 100em) { /* 1600px */
    body {
        font-size: calc(var(--base-desktop-font-size) * 1.2);
    }
}

@media screen and (max-width: 85.375em) { /* 1366px */
    body {
        font-size: var(--base-desktop-font-size);
    }
}

@media screen and (max-width: 64em) { /* 1024px */
    body {
        font-size: var(--base-desktop-font-size);
    }

    input[type=text],
    input[type=number],
    textarea {
        touch-action: none;
    }
}

@media screen and (max-width: 48em) { /* 768px */
    body {
        font-size: calc(var(--base-desktop-font-size) * .75);
    }
}

@media screen and (max-width: 36em) { /* 576px */
}

@media screen and (max-width: 30em) { /* 480px */
}

@media screen and (max-width: 23.5em) { /* 376px */
}

@media screen and (max-width: 20em) { /* 320px */
}
