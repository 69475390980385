.PodcastEpisodeCard {
    border-radius: .5em;
    border: 2px solid #000000;
    box-shadow: -.025em .2em .3em 0 #888888, .025em .2em .3em 0 #888888;
}

.CoverImageLink {
    display: block;
    position: relative;
    border-bottom: 2px solid #000000;
    border-top-left-radius: calc(.5em - 2px);
    border-top-right-radius: calc(.5em - 2px);
    overflow: hidden;
}

.CoverImageLink img {
    width: 100%;
    display: block;
    background-color: #000000;
    z-index: 100;
}

.Overlay {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    color: #FFFFFF;
    border-top-left-radius: calc(.5em - 2px);
    border-top-right-radius: calc(.5em - 2px);
    background-color: rgba(0, 0, 0, .25);
    transition-duration: 250ms;
    transition-timing-function: linear;
    transition-property: background-color, color;
    -moz-transition: background-color 250ms linear, color 250ms linear;
    -o-transition: background-color 250ms linear, color 250ms linear;
    -ms-transition: background-color 250ms linear, color 250ms linear;
    -webkit-transition: background-color 250ms linear, color 250ms linear;
    transition: background-color 250ms linear, color 250ms linear;
    z-index: 120;
}

.CoverImageLink:hover .Overlay,
.CoverImageLink:focus .Overlay,
.CoverImageLink:active .Overlay {
/* .Overlay:hover,
.Overlay:focus { */
    color: #FF7070;
    background-color: transparent;
}

.Overlay svg {
    height: 40%;
    width: auto;
    fill-opacity: 0.5;
    transition-duration: 250ms;
    transition-timing-function: linear;
    transition-property: fill-opacity;
    -moz-transition: fill-opacity 250ms linear;
    -o-transition: fill-opacity 250ms linear;
    -ms-transition: fill-opacity 250ms linear;
    -webkit-transition: fill-opacity 250ms linear;
    transition: fill-opacity 250ms linear;
}

.CoverImageLink:hover .Overlay svg,
.CoverImageLink:focus .Overlay svg,
.CoverImageLink:active .Overlay svg {
    fill-opacity: 1;
    transition-duration: 250ms;
    transition-timing-function: linear;
    transition-property: fill-opacity;
    -moz-transition: fill-opacity 250ms linear;
    -o-transition: fill-opacity 250ms linear;
    -ms-transition: fill-opacity 250ms linear;
    -webkit-transition: fill-opacity 250ms linear;
    transition: fill-opacity 250ms linear;
}

.Description h4,
.FullDescription h4 {
    margin: 1em 0 .25em 0;
}

.Description {
    display: block;
    position: relative;
    margin-top: .75em;
    font-family: AmericanCaptainPatrius1;
    line-height: 1.25em;
    overflow: hidden;
}

.FullDescription {
    width: calc(100% + 2em);
    max-width: 30em;
    height: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    border-radius: .5em;
    border: .2em solid #000000;
    background-color: #FFFFFF;
    z-index: 130;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 250ms;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation: fadeIn 250ms ease-in forwards;
    -o-animation: fadeIn 250ms ease-in forwards;
    -ms-animation: fadeIn 250ms ease-in forwards;
    -webkit-animation: fadeIn 250ms ease-in forwards;
    animation: fadeIn 250ms ease-in forwards;
}

.FullDescription > button {
    margin: .75em auto 0 auto;
}

.FullDescription > span {
    font-family: AmericanCaptainPatrius2;
}

.Resources {
    margin: 1.5em 0 .75em 0;
}

.Resources h4 {
    margin: 1em 0 .5em 0;
}

.ResourcesList {
    margin: .5em 0 1em .5em;
}

.ResourcesList li:not(:last-of-type) {
    margin-bottom: 1em;
}

.Runtime {
    position: absolute;
    bottom: .5em;
    right: .5em;
    padding: .25em .5em;
    border-radius: .25em;
    letter-spacing: .05em;
    color: #FFFFFF;
    background-color: #000000;
    z-index: 110;
}

.Title {
    /* display: inline-block; */
    /* margin-bottom: .5em; */
    font-size: 1.25em;
    font-weight: bold;
    text-decoration-thickness: .15em;
    /* text-underline-offset: .1em; */
    color: #0033BB;
}

.Title:hover,
.Title:focus,
.Title:active {
    color: #913916;
}

.RecordedSequenceDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1.5em;
    margin: .75em .75em 0 .75em;
    font-size: 1.1em;
}

.RecordedSequenceDetails > h3 {
    margin: 0;
}

.RecordedSequenceDetails > span {
    display: block;
    /* white-space: nowrap; */
}

.Summary {
    position: relative;
    padding: 1.25em .75em 1.25em .75em;
    border-bottom-left-radius: calc(.5em - 2px);
    border-bottom-right-radius: calc(.5em - 2px);
    /* background-color: red; */
}

.ClipDescription {
    max-height: 5em;
}

.ContentClip {
    height: 1.25em;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, .5) 8%,
        rgba(255, 255, 255, .6) 24%,
        rgba(255, 255, 255, .7) 38%,
        rgba(255, 255, 255, 1) 44%);
    line-height: 1.25em;
}

.ExpandDescriptionButton {
    position: absolute;
    left: 45%;
    bottom: 0;
    padding: 0;
    margin: 0;
    font-size: 1em;
    border: none;
    color: #0033BB;
    background-color: transparent;
    line-height: 1.25em;
    cursor: pointer;
}

.ExpandDescriptionButton:hover,
.ExpandDescriptionButton:focus,
.ExpandDescriptionButton:active  {
    color: #913916;
}

.MobileOnly {
    display: none;
}

@media screen and (max-width: 30em) {
    .PodcastEpisodeCard {
        padding: .5em;
        border-radius: 0;
        border: none;
        box-shadow: none;
    }

    .CoverImageLink {
        display: none;
    }

    .RecordedSequenceDetails {
        justify-content: flex-start;
        margin: 0;
    }

    .RecordedSequenceDetails > span:first-child {
        margin-right: 4em;
    }

    .Summary {
        padding: 1em .5em 1em .5em;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .FullDescription {
        width: 90%;
        max-width: unset;
    }

    .MobileOnly {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .MobileOnly > a {
        display: block;
        height: 2em;
        width: auto;
        margin-right: .75em;
        color: #0033BB;
        /* background-color: yellow; */
    }

    .MobileOnly > a:hover,
    .MobileOnly > a:focus,
    .MobileOnly > a:active {
        color: #913916;
    }

    .MobileOnly > a > svg {
        height: 100%;
    }

    .MobileOnly > span {
        letter-spacing: .05em;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
