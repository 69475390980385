.TopPanel {
    display: grid;
    grid-template-areas:    "pageHeading pageHeading logoImage"
                            "introVideo motto logoImage";
    grid-template-columns: calc(38% - 5em) calc(36%) calc(26% - 5em);
    grid-template-rows: auto 1fr;
    grid-column-gap: 5em;
    padding: 1em 3%;
    border-bottom: .15em solid var(--red-color-hex);
    background-color: hsl(38, 35%, 77%);
}

.TopPanel h1 {
    grid-area: pageHeading;
}

.IntroVideo {
    grid-area: introVideo;
    height: auto;
    width: 100%;
    max-width: 40em;
    display: block;
    position: relative;
    margin: auto;
    -moz-border-radius: .5em;
    -webkit-border-radius: .5em;
    border-radius: .5em;
    box-sizing: border-box;
    overflow: hidden;
}

.IntroVideo video {
    height: auto;
    /* height: 100%; */
    width: 100%;
    display: block;
    -moz-border-radius: .5em;
    -webkit-border-radius: .5em;
    border-radius: .5em;
    cursor: pointer;
}

.IntroVideo source {
    height: auto;
    width: 100%;
    display: block;
}

.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -moz-border-radius: .5em;
    -webkit-border-radius: .5em;
    border-radius: .5em;
    overflow: hidden;
    color: #FFFFFF;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.Mask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -moz-border-radius: .5em;
    -webkit-border-radius: .5em;
    border-radius: .5em;
    background-color: rgba(0, 0, 0, .35);
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
    -moz-transition: background-color 200ms ease-in, color 200ms ease-in;
    -o-transition: background-color 200ms ease-in, color 200ms ease-in;
    -ms-transition: background-color 200ms ease-in, color 200ms ease-in;
    -webkit-transition: background-color 200ms ease-in, color 200ms ease-in;
    transition: background-color 200ms ease-in, color 200ms ease-in;
}

.Mask svg {
    height: 40%;
    width: auto;
    fill-opacity: 0.5;
    transition-property: fill-opacity;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
    -moz-transition: fill-opacity 200ms ease-in;
    -o-transition: fill-opacity 200ms ease-in;
    -ms-transition: fill-opacity 200ms ease-in;
    -webkit-transition: fill-opacity 200ms ease-in;
    transition: fill-opacity 200ms ease-in;
}

.Mask:hover,
.Mask:focus {
    color: #FF7070;
    background-color: transparent;
}

.Mask:hover svg,
.Mask:focus svg {
    fill-opacity: 1;
    transition-property: fill-opacity;
    transition-duration: 200ms;
    transition-timing-function: ease-in;
    -moz-transition: fill-opacity 200ms ease-in;
    -o-transition: fill-opacity 200ms ease-in;
    -ms-transition: fill-opacity 200ms ease-in;
    -webkit-transition: fill-opacity 200ms ease-in;
    transition: fill-opacity 200ms ease-in;
}

.Hide {
    display: none;
}

.Motto {
    grid-area: motto;
    display: block;
    font-size: 1.4em;
    line-height: 1.75;
    align-self: center;
}

.LogoImage {
    grid-area: logoImage;
    height: auto;
    width: 100%;
    max-width: 30em;
    align-self: center;
}

.Content {
    padding: 2em 1em;
}

.ServiceSummary {
    max-width: 50em;
    margin: 0 1em 2em 1em;
    font-size: 1.25em;
    line-height: 1.75;
}

.Divider {
    height: .2em;
    width: 100%;
    max-width: 60em;
    margin: 2em 0 3em 0;
    background-color: var(--red-color-hex);
    align-self: flex-start;
}

.PodcastReleases > ul {
    display: flex;
    flex-direction: row;
    margin: 1.5em 0 2.5em 1.5em;
}

.PodcastReleases > ul > li {
    width: calc(100%/4 - 2em);
    min-width: 16em;
}

.PodcastReleases > ul > li:not(:last-child) {
    margin-right: 4em;
}

.PodcastReleases > a,
.Resources > a {
    margin-left: 1em;
}

.Resources > ul {
    margin: 1.5em 0 3em 1.5em;
}

.Resources > ul > li {
    display: inline-block;
}

.Resources > ul > li:not(:first-child) {
    padding-top: 1.75em;
}

.Resources > ul > li:not(:last-child) {
    padding-bottom: 1.25em;
    border-bottom: .1em solid #999999;
}

.AffiliateText {
    max-width: 50em;
    margin-top: 3.5em;
}

.AffiliateLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.AffiliateLinks > *:not(:last-child) {
    margin-right: 2em;
}

@media screen and (max-width: 100em) {
    .TopPanel {
        grid-template-columns: calc(38% - 4em) calc(36%) calc(26% - 4em);
        grid-column-gap: 4em;
        padding: 1em;
    }
}

@media screen and (max-width: 85.375em) {
    .TopPanel {
        grid-template-columns: calc(36% - 2.5em) calc(40%) calc(24% - 2.5em);
        grid-column-gap: 2.5em;
    }
}

@media screen and (max-width: 64em) {
    .TopPanel {
        grid-template-areas:    "pageHeading pageHeading"
                                "introVideo logoImage"
                                "motto motto";
        grid-template-columns: calc(100%/5 * 2.25) calc(100%/5 * 1.5);
        grid-template-rows: auto auto auto;
        grid-column-gap: 8em;
    }

    .Motto {
        max-width: 90%;
        margin-top: 1.5em;
        line-height: 1.5;
    }

    .PodcastReleases > ul > li {
        width: calc(100%/4 - 2em);
    }
}

@media screen and (max-width: 48em) {
    .TopPanel {
        grid-template-columns: calc(100%/5 * 3 - 4em) calc(100%/5 * 2 - 4em);
        grid-column-gap: 6em;
    }

    .PodcastReleases > ul > li {
        width: calc(100%/3 - 2em);
    }
}

@media screen and (max-width: 36em) {
    .TopPanel {
        grid-template-columns: calc(100%/5 * 3 - 3em) calc(100%/5 * 2 - 3em);
        grid-column-gap: 4em;
    }

    .PodcastReleases > ul > li {
        width: calc(100%/2 - 2em);
    }
}

@media screen and (max-width: 30em) {
    .TopPanel {
        grid-template-columns: calc(100%/5 * 3 - 1.5em) calc(100%/5 * 2 - 1.5em);
        grid-column-gap: 3em;
        padding: .5em;
    }

    .Motto {
        max-width: 100%;
        margin-top: 1em;
        font-size: 1.3em;
    }

    .PodcastReleases > ul {
        flex-direction: column;
    }

    .PodcastReleases > ul > li {
        width: 100%;
        max-width: 30em;
    }

    .PodcastReleases > ul > li:not(:last-child) {
        padding-bottom: .25em;
        margin-right: 0;
        border-bottom: .05em solid #999999;
    }

    .PodcastReleases > ul > li:not(:first-child){
        padding-top: .75em;
        border-top: .05em solid #999999;
    }
}

@media screen and (max-width: 23.5em) {
    .TopPanel {
        grid-template-columns: calc(100%/5 * 3.25 - .75em) calc(100%/5 * 1.75 - .75em);
        grid-column-gap: 1.5em;
    }

    .AffiliateLinks * {
        margin-bottom: 1.25em;
    }
}

@media screen and (max-width: 20em) {
}
