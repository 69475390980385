.NotFound {
    min-height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em 2em 3em 2em;
    background: linear-gradient(to right,
        rgba(0, 0, 0, 1) 21%,
        rgba(0, 0, 0, .8) 24%,
        rgba(0, 0, 0, .7) 27%,
        rgba(0, 0, 0, .4) 34%,
        rgba(256, 256, 256, 1) 40%);
}

.LogoImage {
    width: 20%;
}

.LogoImage img {
    width: 100%;
    height: auto;
    display: block;
}

.Content {
    width: 60%;
}

.Content p:first-of-type {
    margin-top: 2.25em;
    margin-bottom: 2em;
}

@media screen and (max-width: 48em) {
    .NotFound {
        background: linear-gradient(to right,
            rgba(0, 0, 0, 1) 31%,
            rgba(0, 0, 0, .8) 34%,
            rgba(0, 0, 0, .7) 37%,
            rgba(0, 0, 0, .4) 44%,
            rgba(256, 256, 256, 1) 50%);
    }

    .LogoImage {
        width: 30%;
    }

    .Content {
        width: 50%;
    }
}

@media screen and (max-width: 30em) {
    .NotFound {
        padding: 1em 1em 2em 1em;
        background: linear-gradient(to right,
            rgba(0, 0, 0, 1) 26%,
            rgba(0, 0, 0, .8) 29%,
            rgba(0, 0, 0, .7) 32%,
            rgba(0, 0, 0, .4) 39%,
            rgba(256, 256, 256, 1) 45%);
    }

    .LogoImage {
        width: 25%;
    }

    .Content {
        width: 55%;
    }
}

@media screen and (max-width: 23.5em) {
    .NotFound {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: unset;
    }

    .LogoImage {
        width: 100%;
        max-width: 16em;
        margin-bottom: 1.5em;
        border-radius: .75em;
        overflow: hidden;
    }

    .Content {
        width: unset;
        padding: 0 1em;
    }
}
