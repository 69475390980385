.NavigationItem {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: .5em .25em; */
    font-family: AmericanCaptain1;
    font-size: 1.1em;
    font-weight: 400;
    /* word-spacing: .1em; */
    color: #FFFFFF;
    /* color: #FF6666; */
    /* line-height: 1; */
    /* text-underline-offset: .125em; */
    text-decoration: none;
}

.NavigationItem:hover,
.NavigationItem:focus {
    text-decoration: underline;
}

.NavigationItem:active,
.NavigationItem.active {
    color: #FF7070;
    /* background-color: #555555; */
    text-decoration: underline;
}

@media screen and (max-width: 48em) {
    .NavigationItem {
        height: auto;
    }

    .NavigationItem {
        padding: 1.5em .5em;
    }
}
