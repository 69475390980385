.PrivacyPolicy {
    max-width: 52em;
    margin: 1.5em 1em 2em 1em;
}

.PrivacyPolicy h2 {
    margin-top: 2em;
    margin-bottom: .25em;
}

.PrivacyPolicy p {
    font-size: 1em;
    margin-top: .5em;
}

.PrivacyPolicy a {
    word-wrap: break-word;
    overflow: hidden;
    color: #000799;
    text-decoration: none;
    /* text-underline-offset: .125em; */
}

.PrivacyPolicy a:hover,
.PrivacyPolicy a:focus {
    text-decoration: underline;
}

.PrivacyPolicy a:active {
    text-decoration: underline;
}

.DisplayBlock {
    display: block;
}

@media screen and (max-width: 48em) {
    .PrivacyPolicy {
        max-width: 46em;
    }

    .PrivacyPolicy h2 {
        margin-top: 1.5em;
    }
}
