.Backdrop {
    background-color: rgba(0, 0, 0, .5);
}

.Fixed {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.Absolute {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%;
    height: 100%; */
}
