.TermsOfService {
    max-width: 52em;
    margin: 1.5em 1em 2em 1em;
}

.TermsOfService h2 {
    margin-top: 2em;
    margin-bottom: .25em;
}

.TermsOfService p {
    font-size: 1em;
    margin-top: .5em;
}

.TermsOfService a {
    word-wrap: break-word;
    overflow: hidden;
    color: #000799;
    text-decoration: none;
    /* text-underline-offset: .125em; */
}

.TermsOfService a:hover,
.TermsOfService a:focus {
    text-decoration: underline;
}

.TermsOfService a:active {
    text-decoration: underline;
}

.ContactBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1.5em 0 1em 0;
    box-sizing: border-box;
}

.ContactBlock > * {
    display: block;
    margin: 0;
    line-height: 1.5;
}

.ContactBlock b {
    font-family: AmericanCaptainPatrius;
}

.ContactBlock span {
    font-family: AmericanCaptainPatrius1;
}

@media screen and (max-width: 48em) {
    .TermsOfService {
        max-width: 46em;
    }

    .TermsOfService h2 {
        margin-top: 1.5em;
    }
}
