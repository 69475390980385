.ContactForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: flex-start;
    padding: 1em 1.5em 1em 1.5em;
    border-radius: .5em;
    border: .2em solid #000000;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 .06em .12em rgba(100, 100, 100, .4), -.04em .04em .12em rgba(100, 100, 100, .4), .04em .04em .12em rgba(100, 100, 100, .4);
    -moz-box-shadow: 0 .06em .12em rgba(100, 100, 100, .4), -.04em .04em .12em rgba(100, 100, 100, .4), .04em .04em .12em rgba(100, 100, 100, .4);
    box-shadow: 0 .06em .12em rgba(100, 100, 100, .4), -.04em .04em .12em rgba(100, 100, 100, .4), .04em .04em .12em rgba(100, 100, 100, .4);
    opacity: 0;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 200ms;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.7, 0.5, 0.8);
    -webkit-animation-delay: 100ms;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation: fadeIn 200ms cubic-bezier(0.6, 0.7, 0.5, 0.8) 100ms forwards;
    -o-animation: fadeIn 200ms cubic-bezier(0.6, 0.7, 0.5, 0.8) 100ms forwards;
    -ms-animation: fadeIn 200ms cubic-bezier(0.6, 0.7, 0.5, 0.8) 100ms forwards;
    -webkit-animation: fadeIn 200ms cubic-bezier(0.6, 0.7, 0.5, 0.8) 100ms forwards;
    animation: fadeIn 200ms cubic-bezier(0.6, 0.7, 0.5, 0.8) 100ms forwards;
}

.ContactForm:focus {
    -webkit-box-shadow: 0 .16em .16em rgba(100, 100, 100, .4), -.14em .14em .16em rgba(100, 100, 100, .4), .14em .14em .16em rgba(100, 100, 100, .4);
    -moz-box-shadow: 0 .16em .16em rgba(100, 100, 100, .4), -.14em .14em .16em rgba(100, 100, 100, .4), .14em .14em .16em rgba(100, 100, 100, .4);
    box-shadow: 0 .16em .16em rgba(100, 100, 100, .4), -.14em .14em .16em rgba(100, 100, 100, .4), .14em .14em .16em rgba(100, 100, 100, .4);
}

.ContactForm > b {
    /* font-size: 1.1em; */
    font-size: 1em;
    margin-top: .5em;
    margin-right: 2.5em;
}

.ContactForm label {
    font-size: 1em;
}

.ContactForm input::-webkit-input-placeholder {
    font-size: 1em;
}

.CloseButton {
    height: 1.75em;
    align-self: flex-end;
    padding: 0;
	color: #000000;
    background-color: transparent;
    border: none;
    cursor: pointer;
    -webkit-transform: perspective(1px) scale(1);
    -moz-transform: perspective(1px) scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: perspective(1px) scale(1);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.CloseButton svg {
    height: 100%;
    display: block;
}

.CloseButton:hover,
.CloseButton:focus,
.CloseButton:active {
	color: var(--red-color-hex);
    -webkit-transform: perspective(1px) scale(1.1);
    -moz-transform: perspective(1px) scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: perspective(1px) scale(1.1);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.ContactForm > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2em 0 0 0;
}

.LogoImage {
    width: 16em;
    margin: 1em;
}

.ContactForm form {
    width: 100%;
    /* margin: 1em 1em 1em 1em; */
}

/* .ContactForm form > *:not(:first-child) { */
.Inputs > *:not(:first-child) {
    margin-top: 1.75em;
    /* margin-top: 1.5em; */
}

/* .ContactForm form > *:last-child { */
.ButtonContainer {
    margin: 1em 0 0 2em;
}

/* .ContactForm form > *:nth-child(2) input { */
.Inputs > *:nth-child(2) input {
    width: 40ch;
    max-width: 100%;
}

/* .ContactForm form > *:nth-child(3) input { */
.Inputs > *:nth-child(3) input {
    width: 14ch;
}

/* .ContactForm form > *:nth-child(4) textarea { */
.Inputs > *:nth-child(4) textarea {
    width: 100%;
}

.ContactForm fieldset {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    border: none;
}

.ContactForm form fieldset:first-of-type > *:first-child {
    margin-right: 2em;
}

.ContactForm form fieldset:first-of-type > *:first-child > input {
    width: 20ch;
}

.ContactForm form fieldset:first-of-type > *:nth-child(2) > input {
    width: 20ch;
}

.ButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.ButtonContainer button:not(:last-of-type) {
    margin-right: 5em;
}

@media screen and (max-width: 64em) {
    .LogoImage {
        width: 12em;
    }
}

@media screen and (max-width: 48em) {
    .CloseButton {
        height: 1.25em;
    }

    .LogoImage {
        width: 14em;
    }
}

@media screen and (max-width: 36em) {
    .ContactForm {
        padding: 1em .75em;
    }

    .LogoImage {
        width: 10em;
    }
}

@media screen and (max-width: 30em) {
    .ContactForm {
        width: 95vw;
        padding: 1em 1em;
    }

    .LogoImage {
        display: none;
    }

    .ContactForm form {
        width: 100%;
        margin: 1em 0 1em 0;
    }

    .ContactForm form fieldset:first-of-type > *:first-child {
        margin-bottom: 1.5em;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
