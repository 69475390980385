.MainFooterWrapper {
    min-height: calc(100vh - 4em);
    width: 100%;
    position: absolute;
    margin: 4em 0 0 0;
    -moz-transition: transform 300ms linear 200ms;
    -o-transition: transform 300ms linear 200ms;
    -ms-transition: transform 300ms linear 200ms;
    -webkit-transition: transform 300ms linear 200ms;
    transition: transform 300ms linear 200ms;
    z-index: 50;
    background-color: #FFFFFF;
}

.NotificationDisplayed {
    min-height: calc(100vh - 8.05em);
    margin: 8.05em 0 0 0;
    -moz-transition: margin 400ms ease;
    -o-transition: margin 400ms ease;
    -ms-transition: margin 400ms ease;
    -webkit-transition: margin 400ms ease;
    transition: margin 400ms ease;
}

.Notification {
    height: 4.05em;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 4em;
    left: 0;
    padding: 0 1.2em;
    font-size: 1em;
    color: #FFFFFF;
    border-bottom: .05em solid #000000;
    -webkit-box-shadow: 0 .075em .1em rgb(0, 0, 0, 0.9);
    -moz-box-shadow: 0 .075em .1em rgb(0, 0, 0, 0.9);
    box-shadow: 0 .075em .1em rgb(0, 0, 0, 0.9);
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 400ms;
    -webkit-animation-timing-function: ease;
    -webkit-animation-iteration-count: 1;
    /* -webkit-animation-fill-mode: forwards; */
    -moz-animation: fadeIn 400ms ease;
    -o-animation: fadeIn 400ms ease;
    -ms-animation: fadeIn 400ms ease;
    -webkit-animation: fadeIn 400ms ease;
    animation: fadeIn 400ms ease;
    z-index: 300;
}

.NotificationMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1em;
    font-family: AmericanCaptainPatrius;
}

.NotificationMessage span {
    font-family: AmericanCaptainPatrius;
    letter-spacing: .05em;
    word-spacing: .05em;
}

.IconContainer {
    height: 1.5em;
    margin-right: .75em;
}

.IconContainer > svg {
    height: 100%;
}

.Notification > button {
    height: 1.5em;
    padding: 0;
    margin: 0;
    font-size: 1em;
    border: none;
    color: #FFFFFF;
    background-color: transparent;
    cursor: pointer;
}

.Notification > button > svg {
    height: 100%;
    display: block;
}

.NotificationSuccess {
    background-color: #007900;
}

.NotificationError {
    background-color: #A30000;
}

@media screen and (max-width: 48em) {
    .SlideLeft {
        -moz-transform: translateX(-16em);
        -o-transform: translateX(-16em);
        -ms-transform: translateX(-16em);
        -webkit-transform: translateX(-16em);
        transform: translateX(-16em);
        -moz-transition: transform 200ms linear;
        -o-transition: transform 200ms linear;
        -ms-transition: transform 200ms linear;
        -webkit-transition: transform 200ms linear;
        transition: transform 200ms linear;
    }
}

@media screen and (max-width: 30em) {
    .SlideLeft {
        -moz-transform: translateX(-12em);
        -o-transform: translateX(-12em);
        -ms-transform: translateX(-12em);
        -webkit-transform: translateX(-12em);
        transform: translateX(-12em);
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
