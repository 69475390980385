.IntroContent {
    max-width: 45em;
    display: block;
    margin: 1em 0 1em 0;
    font-size: 1.2em;
    line-height: 1.5;
}

.ChannelMention {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 1.5em 0 1em 0;
}

.ChannelMention span {
    line-height: 1.5;
    margin: auto 1.5em auto 0;
}

.ChannelMention a {
    color: #0033BB;
}

.SpotifyLink {
    height: 3em;
}

.SpotifyLink img {
    height: 100%;
}

.EpisodesContainer {
    max-width: auto;
    padding: 1em 1em 2em 1em;
    /* margin: 2em 1em 2em 1em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.EpisodesContainer > h2 {
    align-self: flex-start;
}

.EpisodesContainer > button {
    margin: 0 auto;
}

.PodcastEpisodes {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 1em;
    margin-bottom: 1em;
    /* background-color: orange; */
}

.PodcastEpisodes > li {
    /* width: calc(100%/4 - 2em); */
    /* min-width: 16em; */

    width: 18em;
}

.PodcastEpisodes > li:not(:last-child) {
    margin: 0 2em 3em 0;
}

@media screen and (max-width: 64em) {
    .IntroContent {
        max-width: 40em;
    }

    .PodcastEpisodes > li {
        /* width: calc(100%/4 - 2em); */
    }
}

@media screen and (max-width: 48em) {
    .ChannelMention {
        align-items: flex-start;
    }

    .PodcastEpisodes > li {
        /* width: calc(100%/3 - 2em); */
    }
}

@media screen and (max-width: 36em) {
    .ChannelMention span {
        margin-right: 0;
        margin-bottom: 0;
    }

    .PodcastEpisodes > li {
        /* width: calc((100%/2) - 2em); */
    }

    .PodcastEpisodes > li:not(:last-child) {
        margin: 0 2em 2em 0;
    }
}

@media screen and (max-width: 30em) {
    .ChannelMention {
        flex-direction: column;
    }

    .SpotifyLink {
        margin-left: 0;
        margin-top: .75em;
    }

    .PodcastEpisodes {
        flex-direction: column;
        margin-left: 0;
    }

    .PodcastEpisodes > li {
        width: 100%;
        max-width: 30em;
    }

    .PodcastEpisodes > li:not(:last-child) {
        padding-bottom: .25em;
        margin: 0;
        border-bottom: .05em solid #999999;
    }

    .PodcastEpisodes > li:not(:first-child) {
        padding-top: .75em;
        border-top: .05em solid #999999;
    }
}
