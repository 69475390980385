.SiteMap {
    margin-top: 3em;
}

.LinkContainer {
    min-height: 40em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: relative;
    padding: 0 2em;
}

.LinkContainer::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
}

.SiteMap h1 {
    width: 100%;
    letter-spacing: .075em;
    word-spacing: .05em;
    text-align: center;
}

.SiteMapList {
    max-width: 50em;
}

.SiteMap ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    margin: 2em 0;
}

.SiteMap li:not(:last-of-type) {
    margin-right: 3em;
    margin-bottom: 2em;
}

.SiteMap a {
    font-size: 1.5em;
    color: #FFFFFF;
}
