.ResourceCard {
    max-width: 40em;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.Title {
    font-size: 1.25em;
    font-family: AmericanCaptainPatrius1;
}

.Description {
    display: block;
    position: relative;
    margin-top: .75em;
    font-family: AmericanCaptainPatrius2;
    overflow: hidden;
}

.ClipDescription {
    max-height: 5em;
}

.ContentClip {
    height: 1.25em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    left: 0;
    bottom: 0;
    /* background-color: rgba(195, 168, 128); */
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, .6) 65%,
        rgba(255, 255, 255, .7) 80%,
        rgba(255, 255, 255, .8) 92%,
        rgba(255, 255, 255, 1) 95%);
    line-height: 1.25em;
}

.ExpandDescriptionButton {
    align-self: flex-end;
    padding: 0;
    margin-right: 2em;
    margin-top: .5em;
    font-size: 1em;
    border: none;
    color: #0000FF;
    background-color: transparent;
    line-height: 1.25em;
    cursor: pointer;
}

.ResourceLinkContainer {
    margin: 0 0 0 1em;
}

.ResourceLinkContainer > li {
    display: inline-block;
    margin-top: 2em;
}

.ResourceLinkContainer > li:not(:last-of-type) {
    margin-right: 2em;
}

.ResourceLinkContainer a {
    display: block;
    line-height: 1;
}
