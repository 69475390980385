.SideDrawer {
    height: calc(100% - 4em);
    width: 16em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 4em;
    right: 0;
    background-color: var(--red-color-hex);
    /* -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%; */
    z-index: 10;
    /* z-index: 1000; */
    border-left: .125em solid #88775C;
    max-height: 0;
    overflow: hidden;
}

.Show {
    max-height: 100%;
}

.Hide {
    max-height: 0;
    -moz-transition: max-height 0ms linear 500ms;
    -o-transition: max-height 0ms linear 500ms;
    -ms-transition: max-height 0ms linear 500ms;
    -webkit-transition: max-height 0ms linear 500ms;
    transition: max-height 0ms linear 500ms;
}

.NavigationWrapper {
    height: 100%;
    width: 100%;
    padding: 3em 1em 1em 1em;
    background-color: #000000;
}

.ScaleUp {
    -moz-transform: scale(100%, 100%);
    -o-transform: scale(100%, 100%);
    -ms-transform: scale(100%, 100%);
    -webkit-transform: scale(100%, 100%);
    transform: scale(100%, 100%);
    -moz-transition: transform 150ms linear 300ms;
    -o-transition: transform 150ms linear 300ms;
    -ms-transition: transform 150ms linear 300ms;
    -webkit-transition: transform 150ms linear 300ms;
    transition: transform 150ms linear 300ms;
}

.ScaleDown {
    -moz-transform: scale(.7, .7);
    -o-transform: scale(.7, .7);
    -ms-transform: scale(.7, .7);
    -webkit-transform: scale(.7, .7);
    transform: scale(.7, .7);
    -moz-transition: transform 100ms linear;
    -o-transition: transform 100ms linear;
    -ms-transition: transform 100ms linear;
    -webkit-transition: transform 100ms linear;
    transition: transform 100ms linear;
}

@media screen and (max-width: 30em) {
    .SideDrawer {
        width: 12em;
    }
}
