.BackgroundGradient {
    background-image: linear-gradient(to right, #000000, #111111 5%, #333333 10%, #888888 30%, #CCCCCC 50%, #888888 70%, #333333 90%, #111111 95%, #000000);
}

.Logo img {
    height: 100%;
}

.Logo a {
    height: 100%;
    display: inline-block;
    text-decoration: none;
    -webkit-transform: perspective(1px) scale(1);
    -moz-transform: perspective(1px) scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: perspective(1px) scale(1);
    /* -webkit-transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out;
    -moz-transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out;
    -o-transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out;
    -ms-transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out;
    transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out; */
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.Logo a:hover,
.Logo a:focus {
    -webkit-transform: perspective(1px) scale(1.05);
    -moz-transform: perspective(1px) scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: perspective(1px) scale(1.05);
}
