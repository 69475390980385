.IntroContent {
    max-width: 45em;
    display: block;
    font-size: 1.2em;
    line-height: 1.5;
}

.About {
    padding: 1em 5% 2em 5%;
}

.About > div:not(:last-of-type) {
    margin-bottom: 4em;
}

.About > div > div:not(:last-of-type) {
    margin-bottom: 2em;
}

.CompanyAbout {
    max-width: 50em;
}

.Bio {
    max-width: 55em;
}

.BioCard {
    width: 16em;
    float: right;
    margin-left: 3em;
    margin-bottom: 1em;
}

.BioCard img {
    width: 100%;
}
.BioCard b {
    font-family: AmericanCaptain;
    font-size: 1.2em;
    letter-spacing: .05em;
    word-spacing: .05em;
}

.BioHeadshot {
    border-radius: .5em;
}

.Sponsors {
    margin-bottom: 2em;
}

.Partners {
    width: 46em;
    margin-top: 1em;
    margin-bottom: 1em;
    outline-style: none;
    position: relative;
}

.Partners ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em 0 2em 1em;
}

.Partners li {
    width: calc((100% - 8em)/ 4);
    margin: 0 2em 2.5em 0;
}

.Partners > button {
    position: absolute;
    right: 2.5em;
    bottom: 0;
    border: none;
    font-size: 1.25em;
    color: #0033BB;
    background-color: transparent;
    cursor: pointer;
}

.Partners > button:hover,
.Partners > button:focus,
.Partners > button:active  {
    color: #913916;
}

.PartnerCard {
    display: block;
    border-top-left-radius: .5em;
    border-top-right-radius: .5em;
    text-decoration: none;
    -webkit-transform: perspective(1px) scale(1);
    -moz-transform: perspective(1px) scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: perspective(1px) scale(1);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-duration: 100ms;
    transition-timing-function: linear;
    transition-property: transform;
    -webkit-transition: transform 100ms linear;
    -moz-transition: transform 100ms linear;
    -o-transition: transform 100ms linear;
    -ms-transition: transform 100ms linear;
    transition: transform 100ms linear;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 300ms;
    -webkit-animation-timing-function: cubic-bezier(0.2, 0.5, 0.8, 1);
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation: fadeIn 300ms cubic-bezier(0.2, 0.5, 0.8, 1) forwards;
    -o-animation: fadeIn 300ms cubic-bezier(0.2, 0.5, 0.8, 1) forwards;
    -ms-animation: fadeIn 300ms cubic-bezier(0.2, 0.5, 0.8, 1) forwards;
    -webkit-animation: fadeIn 300ms cubic-bezier(0.2, 0.5, 0.8, 1) forwards;
    animation: fadeIn 300ms cubic-bezier(0.2, 0.5, 0.8, 1) forwards;
}

.PartnerCard img {
    height: auto;
    width: 100%;
    display: block;
    border-radius: .5em;
    -webkit-box-shadow: 0 .1em .1em rgba(0, 0, 0, .3), -.075em .075em .1em rgba(0, 0, 0, .3), .075em .075em .1em rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 .1em .1em rgba(0, 0, 0, .3), -.075em .075em .1em rgba(0, 0, 0, .3), .075em .075em .1em rgba(0, 0, 0, .3);
    box-shadow: 0 .1em .1em rgba(0, 0, 0, .3), -.075em .075em .1em rgba(0, 0, 0, .3), .075em .075em .1em rgba(0, 0, 0, .3);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-duration: 100ms;
    transition-timing-function: linear;
    transition-property: box-shadow;
    -webkit-transition: box-shadow 100ms linear;
    -moz-transition: box-shadow 100ms linear;
    -o-transition: box-shadow 100ms linear;
    -ms-transition: box-shadow 100ms linear;
    transition: box-shadow 100ms linear;
}

.PartnerCard:hover img,
.PartnerCard:focus img,
.PartnerCard:active img {
    -webkit-box-shadow: 0 .15em .15em rgba(0, 0, 0, .3), -.125em .125em .15em rgba(0, 0, 0, .3), .125em .125em .15em rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 .15em .15em rgba(0, 0, 0, .3), -.125em .125em .15em rgba(0, 0, 0, .3), .125em .125em .15em rgba(0, 0, 0, .3);
    box-shadow: 0 .15em .15em rgba(0, 0, 0, .3), -.125em .125em .15em rgba(0, 0, 0, .3), .125em .125em .15em rgba(0, 0, 0, .3);
}

.PartnerCard > span {
    display: block;
    margin: .375em 0 0 0;
    font-family: AmericanCaptain1;
    font-size: 1.2em;
    text-align: center;
    color: #000000;
    text-shadow: 0 .06em .06em rgba(0, 0, 0, .6);
}

.PartnerCard:hover > span,
.PartnerCard:focus > span,
.PartnerCard:active > span {
    text-shadow: 0 .08em .08em rgba(0, 0, 0, .6);
}

.PartnerCard:hover,
.PartnerCard:focus {
    -webkit-transform: perspective(1px) scale(1.05);
    -moz-transform: perspective(1px) scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: perspective(1px) scale(1.05);
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

@media screen and (max-width: 64em) {
    .IntroContent {
        max-width: 40em;
    }

    .About {
        padding: 1em 1em 2em 1em;
    }
}

@media screen and (max-width: 48em) {
    .BioCard {
        margin-left: 2em;
    }

    .BioCard b {
        display: block;
        margin-right: 2em;
    }

    .Partners {
        width: 30em;
    }

    .Partners li {
        width: calc((100% - 4em)/ 2);
    }
}

@media screen and (max-width: 30em) {
    .BioCard {
        width: 12em;
        margin-left: 1.75em;
    }

    .BioCard b {
        margin-right: 0;
    }

    .Partners {
        width: 28em;
    }
}

@media screen and (max-width: 23.5em) {
    .BioCard {
        width: 80%;
        margin-left: 0;
        float: none;
    }

    .Partners {
        width: 24em;
    }
}

@media screen and (max-width: 20em) {
    .Partners {
        width: 20em;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
